
import { defineComponent } from '@vue/composition-api'
import { mapActions, mapGetters, mapState } from 'vuex'
import { format, parseISO } from 'date-fns'
import frLocale from 'date-fns/locale/fr'
import { Trainer } from '@/api/interfaces/trainer'
import { LogisticSession } from '@/api/interfaces/logisticSession'
import { TrainingSession } from '@/api/interfaces/trainingsession'
import { trainingTypesDriverProgram } from '@/config-constantes'
import { TrainingType } from '@/api/interfaces/trainingtype'

interface NextThreeMonths {
  logisticSessionId: string;
  month: string;
}

export default defineComponent({
  name: 'TrainerSpace',
  components: {
  },
  computed: {
    ...mapState('trainerSpace', {
      trainingSessions: 'trainingSessions',
      loading: 'loading',
    }),
    ...mapState('user', {
      userConnected: 'userConnected',
    }),
    ...mapGetters('user', {
      userConnectedIsTrainerRegionManager: 'isTrainerRegionManager',
    }),
    nextThreeMonths () {
      const occurrencesByMonth: NextThreeMonths[] = []

      this.trainingSessions.forEach((trainingSession : TrainingSession) => {
        (trainingSession.logisticSessions as Array<LogisticSession>).forEach((logisticSession: LogisticSession) => {
          if (logisticSession.startAt) {
            const startAt = parseISO(logisticSession.startAt as string)
            const month : string = format(startAt, 'LLLL', { locale: frLocale })
            const monthNumber : number = parseInt(format(startAt, 'M', { locale: frLocale }))

            if (!occurrencesByMonth[monthNumber]) {
              occurrencesByMonth[monthNumber] = {
                logisticSessionId: (logisticSession['@id'] as string).replaceAll('/', ''),
                month: month,
              }
            }
          }
        })
      })

      const sortedMonths = Object.keys(occurrencesByMonth)
        .map(monthNumber => parseInt(monthNumber))
        .sort((a, b) => a - b)
      const nextThreeMonths = sortedMonths.slice(0, 3)

      return nextThreeMonths.map(month => occurrencesByMonth[month])
    },
  },
  methods: {
    format,
    ...mapActions('trainerSpace', {
      loadTrainingSessions: 'loadTrainingSessions',
    }),
    formatDay (date: string) {
      let formattedDate = ''
      if (date) {
        formattedDate = format(new Date(date), 'EEE dd MMM yyyy', {
          locale: frLocale,
        })
      }
      return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
    },
    formatTime (date: string) {
      return format(new Date(date), "H'h'mm'", {
        locale: frLocale,
      })
    },
    formatMonth (date: string) {
      return format(new Date(date), 'LLLL', {
        locale: frLocale,
      })
    },
    handleTrainingSessionClick (trainingSession: TrainingSession, indexLogisticSession: number) {
      if (this.dialog) {
        // Prevent click on another training session while a dialog is open
        return
      }
      this.trainingSessionSelected = trainingSession
      this.indexLogisticSessionSelected = indexLogisticSession
      this.dialog = true
    },
    getUrlToDownloadTrainingSessionPlanning (id: string|number): string {
      const apiUrl = process.env.NODE_ENV === 'development' ? `${process.env.VUE_APP_BACKEND_BASE_URL}` : '/'

      return apiUrl + 'api/training_sessions/' + id + '/training_planning_sharepoint_file'
    },
    trainerConnectedIsInLogisticSession (logisticSession: LogisticSession): boolean {
      return logisticSession.trainers.some((trainer: Trainer) => trainer.id === this.userConnected?.id)
    },
    isDriverProgram (trainingSession: TrainingSession): boolean {
      return trainingTypesDriverProgram.includes((trainingSession.trainingType as TrainingType).code as string)
    },
  },
  created () {
    this.loadTrainingSessions()
  },
  data () {
    return {
      trainingSessionSelected: null as TrainingSession | null,
      indexLogisticSessionSelected: null as number | null,
      dialog: false,
    }
  },
})
